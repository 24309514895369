import React from "react";
import Select, {components} from 'react-select';
import {InputLabel} from "@mui/material";
import SVGraphics from "../../assets/SVGraphics";


export function SelectAlpha(props) {
    let value = props.data?.find(option => option.value === props.value)
    const NoOptionsMessage = (props) => {
        return (
            <components.NoOptionsMessage {...props} />
        );
    };

    let border=props.isError?"1px solid #f44336":"1px solid #D7DDDF"

    return (
        <div className={props.containerClassName}>
            <InputLabel className={props.labelClassName}>{props.label}</InputLabel>
            {/*<select*/}
            {/*    className={props.inputClassName} name={props.name}*/}
            {/*    disabled={props.disabled} value={props.value}*/}
            {/*    onChange={props.onChange}>*/}
            {/*    <option value={''}>{props.placeholder}</option>*/}
            {/*    {props.data.map(data=>{*/}
            {/*        return <option key={data.value} value={data.value} >{data.label}</option>*/}
            {/*    })*/}
            {/*    }*/}
            {/*</select>*/}

            <Select
                className={`inputClass ${props.inputClassName} ${border}`}
                classNamePrefix="select"
                placeholder={props.placeholder?props.placeholder:'Select...'}
                isSearchable={false}
                onChange={props.onChange}
                isDisabled ={props.disabled}
                selected={props.selected}
                // menuIsOpen={true}
                value={value?value:null}
                components={{ NoOptionsMessage }}
                // name="color"
                options={props.data}
                styles={{
                    control: (base, state) => ({
                            ...base,
                            border: border,
                            backgroundColor: props.inputBg?props.inputBg:"#ffffff",
                            width: "100%",
                            height: "3.5rem",
                            color: "#444444",
                            textTransform: "capitalize",
                            boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.06)",
                            fontSize: "16px",
                            outline: "unset",
                            paddingLeft:props.isIcon?'2.5rem':'0',
                            borderRadius:"0.75rem"
                        })
            }}
            />
            {props.isIcon &&
            <SVGraphics className={`${props.svgClass} select-input-icon`} style={{position:'relative'}} svgname={props.svgName}/>}
        </div>

    )
}
