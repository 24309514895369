import React from "react";
import Select from 'react-select';
import {InputLabel} from "@mui/material";

export function CountryStateSelectAlpha(props) {

        let border=props.isError?"1px solid #f44336":"1px solid #D7DDDF"
    return (
        <div className={props.containerClassName}>
            <InputLabel className={props.labelClassName}>{props.label}</InputLabel>
            {/*<select*/}
            {/*    className={props.inputClassName} name={props.name}*/}
            {/*    disabled={props.disabled} value={props.value}*/}
            {/*    onChange={props.onChange}>*/}
            {/*    <option value={''}>{props.placeholder}</option>*/}
            {/*    {props.data.map(data=>{*/}
            {/*        return <option key={data.value} value={data.value} >{data.label}</option>*/}
            {/*    })*/}
            {/*    }*/}
            {/*</select>*/}

            <Select
                className={props.inputClassName}
                classNamePrefix="select"
                isSearchable={true}
                isDisabled ={props.disabled}
                onChange={props.onChange}
                value={props.value}
                name="color"
                options={props.data}
                styles={{
                    control: (base, state) => ({
                            ...base,
                            border: border,
                            backgroundColor: "#ffffff",
                            width: "100%",
                            height: "3.5rem",
                            color: "#444444",
                            textTransform: "capitalize",
                            boxShadow: 'unset',
                            fontSize: "16px",
                            fontFamily:"Roboto",
                            outline: "unset",
                            borderRadius:"0.75rem"
                        })
            }}
            />
        </div>
    )
}
