import React, {useEffect, useState} from "react";
import './App.css';
import {Route, Routes, useNavigate} from "react-router-dom";
import {authenticationService} from "./services/AuthinticationService";
import {VerificationCode} from "./components/view/auth/VerificationCode";
import {ForgotPassword} from "./components/view/auth/ForgotPassword";
import {Sites} from "./components/view/systemAdmin/Sites";
import {Users} from "./components/view/users/Users";
import {EditUser} from "./components/view/users/users/EditUser";
import {AddUser} from "./components/view/users/users/AddUserNew";
import {Login} from "./components/view/auth/Login";
import {NavBar} from "./components/navigation/NavBar";
import {AddSite} from "./components/view/systemAdmin/sites/AddSiteNew";
import {Chambers} from "./components/view/Chambers";
import {ResetPassword} from "./components/view/auth/ResetPassword";
import {EditSite} from "./components/view/systemAdmin/sites/EditSite";
import {Orders} from "./components/view/Orders";
import {AddChamber} from "./components/view/chambers/AddChamber";
import {EditChamber} from "./components/view/chambers/EditChamber";
import Config from "./config/Config";
import {CalculateOrder} from "./components/view/orders/CalculateOrder";
import {OrderPlan} from "./components/view/orders/OrderPlan";
import LoginAPICalls from "./services/ApiServices/LoginAPICalls";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {ViewPlan} from "./components/view/orders/ViewPlan";
import {GanttNew} from "./components/view/ganttNew/GantNew";
import {Configurations} from "./components/view/Configurations";




function MainRouters() {
    useEffect(()=>{
        const getVersion = async () => {
            let BEVersion = await LoginAPICalls.getVersion()
            setBeVersion(BEVersion.data)
        }
        getVersion().then()

    },[])

    const navigate = useNavigate();
    const [beVersion, setBeVersion] = useState('');
    const [currentUserRoll, setCurrentUserRoll] = useState();

    const pathname = window.location.pathname
    const chambersRoute =  {path: "/chambers", element: <Chambers currentUserRole={currentUserRoll}/>}
    const addChambersRoute =  {path: "/addChamber", element: <AddChamber currentUserRole={currentUserRoll}/>}
    const editChambersRoute =  {path: "/editChamber/:id", element: <EditChamber currentUserRole={currentUserRoll}/>}
    const ganttRoute =  {path: "/gantt", element: <GanttNew currentUserRole={currentUserRoll}/>}
    const usersRoute =  {path: "/users", element: <Users/>}
    const addUserRoute =  {path: "/addUser", element: <AddUser/>}
    const ordersRoute =     {path: "/orders", element: <Orders/>}
    const calculateOrderRoute =  {path: "/orders/calculateOrder/:id", element: <CalculateOrder/>}
    const configurationRoute =  {path: "/configurations", element: <Configurations/>}

    const editUserRoute =  {path: "/editUser/:id", element: <EditUser/>}

    useEffect(()=>{
      let user = authenticationService.currentUserValue

        if(user && user['role']){
            setCurrentUserRoll(user.role[0])
        }
    },)


    const SystemAdminRoutes =
        [
            ganttRoute,
            usersRoute,
            addUserRoute,
            editUserRoute,
            {path: "/systemAdmin/sites", element: <Sites/>},
            configurationRoute,
            {path: "/systemAdmin/addSite", element: <AddSite/>},
            {path: "/systemAdmin/editSite/:id", element: <EditSite/>},
            chambersRoute,
            addChambersRoute,
            editChambersRoute,
            ordersRoute,
            calculateOrderRoute,
            {path: "/orders/orderPlans/:id", element: <OrderPlan/>},
            {path: "/orders/plan/:id", element: <ViewPlan/>},
            // {path: "/systemAdmin/Organization", element: <OrganizationCard/>},
            // {path: "/systemAdmin/Devices", element: <Devices/>}
        ]
    const productionAdminRoutes =
        [
            ganttRoute,
            usersRoute,
            {path: "/addUser", element: <AddUser/>},
            {path: "/editUser/:id", element: <EditUser/>},
            configurationRoute,
            // {path: "/addSite", element: <AddSite/>},
            // {path: "/editSite/:id", element: <EditSite/>},
            chambersRoute,
            addChambersRoute,
            editChambersRoute,
            ordersRoute,
            calculateOrderRoute,
            {path: "/orders/orderPlans/:id", element: <OrderPlan/>},
            {path: "/orders/plan/:id", element: <ViewPlan/>},
            // {path: "/systemAdmin/Organization", element: <OrganizationCard/>},
            // {path: "/systemAdmin/Devices", element: <Devices/>}
        ]
    const teamLeaderRoutes =
        [
            ganttRoute,
            chambersRoute,
            editChambersRoute,
            ordersRoute,
            calculateOrderRoute,
            {path: "/orders/orderPlans/:id", element: <OrderPlan/>},
            {path: "/orders/plan/:id", element: <ViewPlan/>},
            // {path: "/systemAdmin/Organization", element: <OrganizationCard/>},
            // {path: "/systemAdmin/Devices", element: <Devices/>}
        ]
    //Engineer
    const engineerRoutes =
        [
            ganttRoute,
            chambersRoute,
            editChambersRoute,
            ordersRoute,
            {path: "/orders/orderPlans/:id", element: <OrderPlan/>},
            {path: "/orders/plan/:id", element: <ViewPlan/>},
            // {path: "/systemAdmin/Organization", element: <OrganizationCard/>},
            // {path: "/systemAdmin/Devices", element: <Devices/>}
        ]

    // const carGiverRouts =
    //     [
    //         {path: "/clinician/Patient", element: <Patient/>},
    //         {path: "/clinician/Patients", element: <Patients/>}
    //     ]



    const handleLogout = async () => {
        authenticationService.logout();
        navigate("/login");
    };
    const changeSite = () => {
        setCurrentUserRoll(authenticationService.currentUserValue.role[0])
    }


    return (
        <div
            className={`main w-full h-full bg-tableBg font-Roboto ${pathname !== '/login' && pathname !== '/' && pathname !== '/verificationCode' && pathname !== '/forgotPassword' ? 'bg-tableBg' : 'bg-[#EBF2FC]'}`}
        >
            <div className={"main w-full h-full flex justify-between "}>
                {pathname !== '/login' && pathname !== '/' && pathname !== '/verificationCode' && pathname !== '/forgotPassword' && pathname !== '/ResetPassword' ?
                    <NavBar logoutUser={() => handleLogout()} changeSite={()=>changeSite()}/> : null
                }

                <Routes>
                    {currentUserRoll === 'SystemAdmin' ?
                        SystemAdminRoutes.map(route => {
                            return <Route key={route.path} path={route.path} element={route.element}/>
                        })
                        : currentUserRoll === 'ProductAdmin' ?
                            productionAdminRoutes.map(route => {
                                return <Route key={route.path} path={route.path} element={route.element}/>
                            })
                            : currentUserRoll === 'TeamLeader' ?
                            teamLeaderRoutes.map(route => {
                                return <Route key={route.path} path={route.path} element={route.element}/>
                            })
                                : currentUserRoll === 'User' || currentUserRoll === 'Engineer'|| currentUserRoll === 'Viewer'?
                                    engineerRoutes.map(route => {
                                return <Route key={route.path} path={route.path} element={route.element}/>
                            })
                            :

                        null}
                    <Route exact path="/verificationCode" element={<VerificationCode/>}/>
                    <Route exact path="/forgotPassword" element={<ForgotPassword/>}/>
                    <Route exact path="/ResetPassword" element={<ResetPassword/>}/>
                    <Route exact path="/login" element={<Login/>}/>
                    <Route exact path="/" element={<Login/>}/>
                    {/*<Route path="*" exact={true} element={<NotFoundPage/>}/>*/}
                </Routes>
            </div>

            <OverlayTrigger
                placement={"top"}
                delay={{show: 250, hide: 750}}
                trigger={["hover", "focus"]}
                // show={true}
                // offset={-80}
                rootClose
                overlay={<Tooltip id="tooltip-disabled" className={"versionToolTip"}>
                    <span className={'text-slate-400 px-4 py-10'}>be-V:{beVersion?.version} ({beVersion?.Build})</span>
                </Tooltip>}>
                <span className={'absolute bottom-3 right-8 text-slate-400'}>{Config.version} ({Config.BuildVersion})

                </span>
            </OverlayTrigger>


        </div>
    );
}

export default MainRouters;
