import React from "react";
import {InputLabel} from "@mui/material";
import PhoneInput from 'react-phone-input-2'
import "../../style/input/phoneInput.css"
import 'react-phone-input-2/lib/material.css'

export function PhoneInputAlpha(props) {
    let border=props.isError?"border border-[#f44336]":"border border-[#D7DDDF]"

    return (
        <div className={`alpha-phoneInput ${props.containerClassName}`} key={props.key+"div"}>
            <InputLabel className={props.labelClassName}>{props.label}</InputLabel>
            <PhoneInput  name={props.name}
                        inputClass={`disabled:text-[#00000061] font-Roboto ${props.inputClassName} ${border}`}
                        placeholder={props.placeholder} onChange={props.onChange}
                        // value={props.value}
                        disabled={props.disabled}
                        id={props.key+"-input"}
                        key={props.key + "-input"}
                        type={'phoneNumber'}
                        country={props.country}
                        enableSearch
                        searchPlaceholder={"Search"}
                        searchClass={'rounded-md'}
                         value={props.value}
                        // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        maxLength="15"
                        required/>

        </div>
    )
}
