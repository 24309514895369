import React, {useEffect, useState} from "react";
import {FormCard} from "../../forms/FormCard";
import formData from "../../forms/formsData.json";
import {AlphaForm} from "../../forms/AlphaForm";
import {TextAlpha} from "../../input/TextAlpha";
import {useNavigate} from "react-router-dom";
import {object, string,number} from "yup";
import SystemAdmin from "../../../services/ApiServices/SystemAdminApi";
import ChambersApi from "../../../services/ApiServices/ChambersApi";
import {AlertConfirm} from "../../modals/Confirm";
import SVGraphics from "../../../assets/SVGraphics";
import {authenticationService} from "../../../services/AuthinticationService";
import Constants from "../../../utils/constants";


export function AddChamber(props) {
    const navigate = useNavigate();
    const [formType, setFormType] = useState(formData['add_chamber'])
    const [entry, setEntry] = useState({Status: 'maintenance'});
    const [error, setError] = useState({})
    const [isError, setIsError] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const right =<span className={"text-[#444444] text-2xl text-titleSize ml-2"} >&#8250;</span>

    const chamberSchema = object().shape({
        SiteID: string().required('Please insert Site ID'),
        ChamberID: string().required('Please insert Chamber ID'),
        GloveBoxNumber: number().typeError('Please insert Number').default(null).nullable()
    });
    useEffect(() => {
        const user = authenticationService.currentUserValue
        let statusOptions = [{label:'Maintenance', value:'maintenance'}]
        let form =JSON.parse(JSON.stringify(formType))
        let chamber = {...entry}
        let sites = []
        if(user?.role[0] ==='SystemAdmin'){
            fetchData().then(p=>{

                p.data.forEach(site=>{
                    sites.push({value:site.SiteID,label:site.SiteName})
                })



            })
        }else{
            let currentSite = user?.profile.Sites.find(x=>x.SiteID ===user?.profile.SiteID)
            sites=[{value:currentSite?.SiteID,label:currentSite?.SiteName}]
            chamber['SiteID'] = user?.profile.SiteID
            form[0]['disabled'] = true
        }
        form[0]['data']=sites
        form[3]['data']=statusOptions
        chamber['Status']='maintenance'
        setEntry(chamber)
        setFormType(form)
    },[])
    const fetchData= async()=> {
        return await SystemAdmin.getAllSitesAdvanced({filters:[]})
    }
    const createChamber = async() => {
        let chamber = {...entry}
        let checkExist = await ChambersApi.CheckChamberExisting(chamber.ChamberID,chamber.SiteID)

        if(checkExist && checkExist.data){
            let title = <div className={'w-full flex justify-center'}><SVGraphics svgname={'alert-triangle'} className={'w-[2.5rem] h-[2.5rem]'}/></div>
            let message = <div>{`Chamber ${chamber.ChamberID} already exist.`} <br/> <br/>Are you sure you want to replace it?</div>
            let alert = await AlertConfirm({},message,title)
            if(alert){
                await submit(chamber)
            }
        }else{
            await submit(chamber)
        }
    }
    const submit = async (chamber) => {
        let submit = await ChambersApi.createChamber(chamber)
        if(submit){
            navigate('/chambers')
        }
    }
    const onChange = async (name, value) => {
    let chamber = {...entry}
        let form = [...formType]
        let formErrors = {...error}
        if(name==='ChamberID'){
            value = value.replace(/[^\d.-]+/g, '')
            let gloveBox = value.substring(0).charAt(0)
            // let gloveBox = value.substring(2).charAt(0)
            // value=Number(value)
            chamber['GloveBoxNumber'] = Number(gloveBox)
        }else if(name==='SiteID'){
            value=value.value
        }
        if (value === "") {
            value = null
        }
        chamber[name]=value
        await chamberSchema.validate(chamber,{abortEarly: false}).then(function(value) {
            formErrors[name] = false
            setDisabled(false)
            setIsError(false)
        })
            .catch(function(err) {
                let index = form.findIndex(x=>x.name===name)
                err.inner.forEach(error=>{
                    if(error.path===name){
                            formErrors[error.path] = true
                        form[index]['error'] = error.errors
                        setDisabled(true)
                        setIsError(true)
                    }
                })
                let errorIndex =err.inner.findIndex(x=>x.path===name)
                if(errorIndex===-1 && index>-1){
                    formErrors[name] = false
                    form[index]['error'] = []
                }
            });
        setEntry(chamber)
        setFormType(form)
        setError(formErrors)
    }

    return (
        <div className="w-full h-full pt-32 ml-24 overflow-auto">
            <div className={'w-11/12'}>
            <div className={'mb-7'}>
                <TextAlpha text={"Chambers"}  className={"text-alphaTauText text-titleSize cursor-pointer font-bold"}
                           onClick={()=>navigate('/chambers')}/>
                <TextAlpha text={right}  />
                <TextAlpha text={" New chamber"}  className={"text-alphaTauButton text-titleSize"}/>

            </div>
            <FormCard
                cardClass={'pb-[1.875rem]'}
                cardTitle={"Chamber info"}
            data={ <AlphaForm
                data={formType}
                formData={entry}
                onChange={e => onChange(e.target.name, e.target.value)}
                containerClassName={"mb-2 mr-12"}
                labelClassName={Constants.inputLabelClass}
                inputClassName={Constants.inputClass}
                isShown={error}
                isError={isError}
            />}
            />
            <div  className={`my-8 flex mt-[12rem] w-full lg:flex-row sm:flex-col justify-end`}>
                <button type="button"
                        disabled={disabled}
                        onClick={()=>createChamber()}
                        className={`${Constants.buttonClass} mr-12 w-loginButton text-white bg-alphaTauButton hover:bg-alphaTauButtonHover font-Roboto font-medium rounded-rounded10 text-textSize px-5 py-2.5 text-center`}>
                    Create
                </button>
                <div className={'underline underline-offset-4 text-alphaTauButton text-[1.313rem]'} onClick={()=>navigate('/chambers')}>Cancel</div>
            </div>
            </div>
        </div>
)

}