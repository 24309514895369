import React, {useEffect, useState} from "react";
import ChambersApi from "../../../services/ApiServices/ChambersApi";
import moment from "moment";
import Constants from "../../../utils/constants";
import {v4 as uuidv4} from "uuid";
import utils from "../../../utils/utils";
import FirstAsymptoticActivityCalculation from "../../modals/FirstAsymptoticActivityCalculation";

export function AsymptoticActivityTable(props) {

    const [data, setData] = useState([])
    const [openCalculate, setOpenCalculate] = useState(false)
    const [batches, setBatches] = useState([])
    const [asymptoticData, setAsymptoticData] = useState()

    const headers = props.chamber.Status === 'maintenance' ? ['Measurement Date', 'Measurement Time', 'First and Last Name', 'Approved to Calculation'] :
        ['Order', 'Type', 'Measurement Date', 'Measurement Time', 'First and Last Name', 'Select for Calculation']

    const firstLineClass = 'h-full flex items-center justify-center text-[#252B42] font-Roboto text-[1.125rem]'
    const thClass = 'w-[10.8rem] text-[#444444] text-[1.1rem] ml-[0.313rem] mr-2'
    useEffect(() => {
        const fetchData = async () => {

            let measurememts
            if (props.chamber.Status === 'maintenance') {
                measurememts = await ChambersApi.getChamberMeasurmentInfo(props.chamber.chamberInternalID)
            } else {
                measurememts = await ChambersApi.getActiveChamberMeasurementInfo({siteID: props.chamber.SiteID}, props.chamber.chamberInternalID)
            }
            if (measurememts) {
                setData(measurememts.data)
            }
        }
        fetchData().then()
    }, [])

    const calculateMeasurements = async () => {

        let body = {
            chamberInternalID: props.chamber.chamberInternalID,
            siteID: props.chamber.SiteID,
            batches: batches
        }
        if (props.chamber.Status === 'maintenance') {
            let calculate = await ChambersApi.runAsymptoticScript(body)
            if (calculate) {
                 setAsymptoticData(calculate.data)
                await setOpenCalculate(true)
            }

        } else {
            delete body['siteID']
            let save = await ChambersApi.saveBatchesToRun(body)
            if (save && save.data && !save.data['error']) {
                props.cancelCalculate()
            }
        }
    }
    const selectBatche = (id) => {
        let _batches = [...batches]
        let batcheIndex = batches.findIndex(x => x === id)
        if (batcheIndex > -1) {
            _batches.splice(batcheIndex, 1)
        } else {

            if ((props.chamber.Status === 'active' && _batches.length < 2) || props.chamber.Status === 'maintenance') {
                _batches.push(id)
            }
        }
        setBatches(_batches)
    }
    const addNewCharge = () => {
        setOpenCalculate(false)
        props.addNewCharge()
    }

    return (
        <div>
            {props.chamber.lastCalcTime &&
            <div className={'flex justify-end text-[#444444] font-Roboto text-[1.125rem] mr-2'}><div className={'font-semibold mr-2 '}>Last calculation: </div>{`${moment(props.chamber.lastCalcTime).format('DD MMM YYYY hh:mm')}`}</div>
            }

            <div
                className={`grid gap-4 ${props.chamber.Status === 'maintenance' ? "grid-cols-4" : "grid-cols-6"} bg-[#F2F5FA] h-[3.938rem] border border-[#EDEDED] rounded-[0.625rem]`}>
                {headers.map(header => {
                    return <div key={header}
                                className={'h-full flex items-center justify-center text-[#444444] font-Roboto font-bold text-[1.20rem]'}
                                >{header}</div>
                })}
            </div>
            {
                data.map(d => {
                    const gridStylesRA = {
                        gridTemplateColumns: `repeat(${d.RaActivity.length}, minmax(0, 1fr))`,
                        gap: '0.15rem',
                    };
                    return <div key={d.BatchID} className={'mt-[1.25rem]'}>
                        <div
                            className={`grid gap-4  ${props.chamber.Status === 'maintenance' ? "grid-cols-4" : "grid-cols-6"} bg-[#F2F5FA] h-[3.938rem] border border-[#EDEDED] rounded-t-[0.625rem]`}>
                            {props.chamber.Status === 'active' &&
                            <div className={firstLineClass}>{d.OrderName}</div>
                            }
                            {props.chamber.Status === 'active' &&
                            <div
                                className={firstLineClass}>{d.OrderType ? utils.getOrderTypeLabel(d.OrderType) : '  '}</div>
                            }
                            <div className={firstLineClass}>{moment(d.MeasureDate).format('MMM DD YYYY')}</div>
                            <div className={firstLineClass}>{moment(d.MeasureDate).format('HH:MM AM')}</div>
                            <div className={firstLineClass}>{d.ApprovedBy}</div>
                            <div className={firstLineClass}><input
                                className={'w-[1.563rem] h-[1.563rem] rounded-[0.25rem] border border-[#F2F5FA]'}
                                checked={batches.find(x => x === d.BatchID) === d.BatchID} type={'checkbox'}
                                onClick={() => selectBatche(d.BatchID)}/></div>
                        </div>
                        <div className={'bg-[#F6F6F6] h-[3.5rem] flex items-center border-y border-[#EDEDED] py-8'}>
                            <div className={thClass}>Expected Ra activity at procedure (kBq)</div>
                            <div className={`grid `} style={gridStylesRA}>
                                {d?.RaActivity?.map(activity => {
                                    return <div className={'w-[3.75rem]'} key={activity + uuidv4()}>{activity?activity:'NA'}</div>
                                })}
                            </div>
                        </div>
                    </div>
                })
            }
            <div className={`my-8 flex mt-[6rem] w-full justify-end`}>
                <div className={'flex items-end'}>
                    <button type="button"
                            disabled={batches.length < 1}
                            onClick={() => calculateMeasurements()}
                            className={`${Constants.buttonClass} mr-12 w-loginButton text-white bg-alphaTauButton hover:bg-alphaTauButtonHover font-Roboto font-medium rounded-rounded10 text-textSize px-5 py-2.5 text-center`}>
                        {props.chamber.Status === 'active' ? 'Save' : 'Calculate'}
                    </button>
                    <button className={`${Constants.buttonClass} underline underline-offset-4 text-alphaTauButton`}
                         onClick={() => props.cancelCalculate()}
                    >
                        Cancel
                    </button>
                </div>
            </div>
            {openCalculate &&
            <FirstAsymptoticActivityCalculation
                onCancel={() => {
                    setOpenCalculate(false)
                    setBatches([])
                }}
                asymptoticData={asymptoticData}
                addNewCharge={()=>addNewCharge()}
                chamber={props.chamber}
                saveActivityLevel={props.saveActivityLevel }
            />
            }
        </div>
    )
}