import React, {useEffect, useState} from "react";
import SystemAdmin from "../../../services/ApiServices/SystemAdminApi";
import {Table} from "../../table/table";
import {useNavigate} from "react-router-dom";
import {SelectAlphaIcon} from "../../input/SelectAlphaIcon";
import SVGraphics from "../../../assets/SVGraphics";
import {SearchInputButtonComponent} from "../../input/SearchInputButtonComponent";
import {TableRowOptions} from "../../options/TableRowOptions";
import {AlertConfirm} from "../../modals/Confirm";
import {authenticationService} from "../../../services/AuthinticationService";
import Constants from "../../../utils/constants";


export function Users() {
    const navigate = useNavigate();
    const roleOptions=[
        {"label": "Viewer", "value": "Viewer"},  {"label": "User", "value": "User"},  {"label": "Engineer", "value": "Engineer"}
        , {"label": "Team leader", "value": "TeamLeader"},{"label": "Production Admin", "value": "ProductAdmin"},  {"label": "System Admin", "value": "SystemAdmin"}
    ]
    const [isLoading, setIsLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [usersFilter, setUsersFilter] = useState({ filters:[]});
    const [users, setUsers] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [searchInputs, setSearchInputs] = useState( [
        {name:"SiteID",label:"Site",type:"Select",data:[],hasLine:true,svgName:"countryLocation",disabled:false},
        {name:"Role",label:"Role",type:"Select",data:[],hasLine:true,svgName:"countryLocation",disabled:false}
    ]);




    useEffect( () => {
        setIsLoading(true)

        let user = authenticationService.currentUserValue.role[0]
        if(user === "ProductAdmin"){
            let _searchInputs=[{name:"Role",label:"Role",type:"Select",data:[],hasLine:true,svgName:"countryLocation",disabled:false}]
            setSearchInputs([..._searchInputs])
        }
        setUserRole(user)
        fetchData(user).then()


            // setUsers(p.data)
            // setFilteredData(p.data)
            // setIsLoading(false)
        // setIsLoading(false)

    },[])



      /*  const fetchDataGetUsersFilters = async () => { //get patient data from db
             let usersFilters = {data: [{label:'test',value:'test'}]}
            //TODO
            //  let usersFilters = await SystemAdmin.getUsersCountry()
            if (usersFilters && usersFilters.data ) {
                usersFilters = usersFilters.data
                setCountryStates(usersFilters)
                let countries = []
                usersFilters.forEach(country=>{
                    countries.push({label:country.Country,value:country.Country})
                })
                inputs.forEach(input=>{
                    if(input.name === "Country"){
                        input.data=countries
                    }
                })
                setSearchInputs(inputs)
            }
        }
        fetchDataGetUsersFilters().then()

    }, [])*/


    const fetchData= async(user)=> {
        let inputs = []
        let siteOptions = []
        if(user === "SystemAdmin") {
            let sites = await SystemAdmin.getAllSitesAdvanced({filters: []})
            if (sites && sites.data) {
                sites = sites.data
                sites.forEach(item => {
                    siteOptions.push({value: item.SiteID, label: item.SiteName})
                })
            }
        }
        let usersApi= await SystemAdmin.getAllUsers(usersFilter)
        if(usersApi && usersApi.data){
            let usersArr= [...usersApi.data]
            let finalUsers=[]
            for (let i = 0; i < usersArr.length; i++) {
                let userSites=usersArr[i].userSites?usersArr[i].userSites:[]
                let userObj={...usersArr[i],...usersArr[i].PII}
                userObj['Role']=""
                userObj['Site']=""
                userObj['SiteOptions']=[]
                userObj['RoleOptions']=[]

                let userCount=-1

                userSites.forEach((item,index)=>{
                    if(item.isActive){
                        let site=item.Site
                        let roleIndex =roleOptions.findIndex(option=>option.value===item.Role[0])
                        if(userCount===-1){
                            userObj['RoleOptions']=[roleOptions[roleIndex].label]
                            userObj['SiteOptions']=[site.SiteName]
                            userCount=userCount+1
                        }
                        else{
                            if(userObj['RoleOptions'].indexOf(roleOptions[roleIndex].label)<0){
                                userObj['RoleOptions'].push(roleOptions[roleIndex].label)
                            }
                            if(userObj['SiteOptions'].indexOf(site.SiteName)<0){
                                userObj['SiteOptions'].push(site.SiteName)
                            }
                            userCount=userCount+1
                        }
                    }
                })

                finalUsers.push(userObj)
            }
            let _searchInputs=searchInputs
            if(user === "SystemAdmin"){
                _searchInputs[0].data=siteOptions
                _searchInputs[1].data=roleOptions
            }else if(user === "ProductAdmin"){
                _searchInputs[0].data=siteOptions
            }
            setSearchInputs([..._searchInputs])
            setFilteredData(finalUsers)
            setUsers(finalUsers)
            setIsLoading(false)
        }
        return []
    }

    const unlockUser=async (userID) => {
        let alert = await AlertConfirm(
            {},
            "Are you sure you want to unlock user",
            "Unlock User")
        if (alert) {
            let usersApi = await SystemAdmin.unlockUser(userID)
            if(usersApi){
                fetchData().then(p=>{
                    setIsLoading(false)
                })
            }
        }

    }

    const columns = [
        {Header: 'First Name', accessor: 'FirstName',show:true, Cell:(cell)=>{
                return(<div className={'cursor-pointer'} onClick={() => navigate(`/editUser/${cell.row.original?.UserStorageId}`,{ state: cell.row.original })}>
                    {cell.row.original?.FirstName}
                </div>)}},
        {Header: 'Last Name', accessor: 'LastName',show:true, Cell:(cell)=>{
                return(<div className={'cursor-pointer'} onClick={() => navigate(`/editUser/${cell.row.original?.UserStorageId}`,{ state: cell.row.original })}>
                    {cell.row.original?.LastName}
                </div>)}},
        {Header: 'Email', accessor: 'Email',show:true, Cell:(cell)=>{
                return(<div className={'cursor-pointer'} onClick={() => navigate(`/editUser/${cell.row.original?.UserStorageId}`,{ state: cell.row.original })}>
                    {cell.row.original?.Email}
                </div>)}},
        {Header: 'Production Site', accessor: 'SiteName',show:userRole === "SystemAdmin",

            Cell:(cell)=>{
                return(<TableRowOptions
                        onButtonClick={() => navigate(`/editUser/${cell.row.original?.UserStorageId}`,{ state: cell.row.original })}
                    options={cell.row.original?.SiteOptions} />)
        }},
        {Header: 'Role', accessor: 'Role',show:true, Cell:(cell)=>{
                return(<TableRowOptions
                    onButtonClick={() => navigate(`/editUser/${cell.row.original?.UserStorageId}`,{ state: cell.row.original })}
                    options={cell.row.original?.RoleOptions} />)
                }},

        {Header: 'Lock', accessor: 'Enabled',show:userRole === "SystemAdmin", Cell:(cell)=>{
            if(!cell.row.original.Enabled){
                return(<div className={'cursor-pointer'} onClick={() => unlockUser(cell.row.original.UserStorageId)}>
                    <SVGraphics className={'w-5 h-5 cursor-pointer'} svgname={'lock-user'}/>
                </div>)}
            }

        },
    ]

    const handleSearch=(event)=>{
        let filterValue = event.target.value.toLowerCase()
        let filteredData =[]
        for (let i = 0; i < users.length; i++) {
            filteredData = users.filter(user =>
                String(user['FirstName']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['LastName']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['Email']).toLowerCase().indexOf(filterValue) > -1

            )
        }
        setFilteredData(filteredData)

    }

    const filterOnChange = async (e, type) => {
        let filters = {...usersFilter}
            let statusFilter = filters.filters.findIndex(x=>x.name===type)
            if(statusFilter>-1){
                if(!e.target.value){
                    filters['filters'].splice(statusFilter,1)
                }else{

                    filters['filters'][statusFilter]['value'] = e.target.value.value
                }
            }else{
                filters.filters.push({name:type,value:e.target.value.value})
            }

        setUsersFilter(filters)
        fetchData(userRole).then(p=>{
            setIsLoading(false)
        })
    }

    const clearFilters = async () => {
        let filter = {...usersFilter}
        filter.filters.splice(0, filter.filters.splice(0, filter.filters.length))
        setUsersFilter({...filter})
        fetchData(userRole).then(p=>
            setIsLoading(false)
        )
    }

    return(
    <div className={Constants.mainScreenClass}>
                    <div className={"flex flex-wrap w-95 gap-3.5"}>
                        {searchInputs.map(item =>{
                            return <SelectAlphaIcon
                                key={item.name}
                                svgName={item.svgName}
                                data={item.data}
                                name={item.name}
                                label={item.label}
                                value={usersFilter['filters'].find(filter=>filter.name===item.name)?.value}
                                selected={usersFilter['filters'].find(filter=>filter.name===item.name)?.value}
                                type={item.type}
                                disabled={item.disabled}
                                onChange={(e)=>filterOnChange(e,item.name )}
                                hasLine={item.hasLine}
                                inputClassName={'Select-Icon'}
                            />
                        })}
                        <div className={'ml-[3rem] text-alphaTauButton text-[1.25rem] h-[3.5rem] font-bold font-Roboto flex items-center cursor-pointer'} onClick={()=>clearFilters()}><span className={'mr-4'}>&#10005;</span> Clear all</div>
                    </div>
        <div className={"w-width90 "}>
            <SearchInputButtonComponent
                buttonText={"+ Create new user"}
                hasButton={userRole==='SystemAdmin' || userRole==='ProductAdmin'}
                inputClass={""}
                addNewOnClick={()=>navigate('/addUser')}
                onChange={(e)=>handleSearch(e)}/>

            <Table data={filteredData?filteredData:[]} columns={ columns.filter((column) => column.show===true)} isLoading={isLoading}/>

        </div>




    </div>
)
}