import React, {useState, useEffect, forwardRef} from 'react';
import moment from "moment";
import {OrdersGanttView} from "./OrdersGanttView";
import {DeliveryGanttView} from "./DeliveryGanttView";
import Constants from "../../../utils/constants";
import {SelectAlphaIcon} from "../../input/SelectAlphaIcon";
import SVGraphics from "../../../assets/SVGraphics";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {GanttNotes} from "./gantComponenets/GanttNotes";
import {authenticationService} from "../../../services/AuthinticationService";
import SystemAdmin from "../../../services/ApiServices/SystemAdminApi";
import GanttApi from "../../../services/ApiServices/GanttApi";
import utils from "../../../utils/utils";
import OrdersApi from "../../../services/ApiServices/OrdersApi";
import {EditOrderPlan} from "../orders/EditOrderPlan";


export function GanttNew(props) {
    const [startDate, setStartDate] = useState(new Date()); // Initial start date
    const [endDate, setEndDate] = useState(new Date()); // Initial end date
    const [currentRange, setCurrentRange] = useState([]);
    const [currentMonth, setCurrentMonth] = useState("");
    const [currentOrder, setCurrentOrder] = useState({});
    const [currentPlan, setCurrentPlan] = useState({});
    const [isEditOrder, setIsEditOrder] = useState(false);
    const [isGanttNote, setIsGanttNote] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const [ganttFilter, setGanttFilter] = useState({filters: []});
    const [ganttOrders, setGanttOrders] = useState([]);
    // const [ordersNum, setOrdersNum] = useState([]);
    const [originOrders, setOriginOrders] = useState([]);
    const [siteID, setSiteID] = useState("");
    const [siteName, setSiteName] = useState("");
    const chambersColors= ['#EBFBFF','#DBECE9'];
    const [searchInputs, setSearchInputs] = useState(Constants.gantt_searchInputsSystem);


    useEffect( () => {
        let user = authenticationService.currentUserValue
        setCurrentUser(user)
        if(user?.role[0] !== "SystemAdmin") {
            let _searchInputs = Constants.gantt_searchInputs
            setSearchInputs([..._searchInputs])
        }

        getRange(new Date(new Date().setDate(new Date().getDate() + 4)))
        setEndDate(new Date(new Date().setDate(new Date().getDate() + 4)))
        updateSearchInput(user).then()

        // setOrderColors(['#2F6A8E', '#8C3746', '#D58D1F', '#697987', '#24857A'])
    }, [])

    const updateSearchInput = async (user) => {
        let _searchInputs = [...searchInputs]
        if(user?.role[0] === "SystemAdmin") {
            let _sites = await getSites()
            if(_sites){
                _searchInputs[0].data=_sites
            }
        }else{
            setSiteID(user?.profile?.SiteID)
            getGanttData(user?.profile?.SiteID,'').then()
        }
        await setSearchInputs(_searchInputs)
    }

    const getSites = async() => {
        return await fetchData()
    }

    const fetchData = async () => {
        let _sites = await SystemAdmin.getAllSitesAdvanced({filters: []})
        let options =[]
        if(_sites && _sites.data){
            await _sites.data.forEach(s => {
                options.push({value: s.SiteID, label: s.SiteName})
            })
        }
        return options
    }
    const planSaved = () => {
        getGanttData(siteID,'SiteID',startDate,endDate).then()
        setIsEditOrder(false)
    }
    const getGanttData = async (siteId,type,start,end) => {
        // let siteId = ganttFilter.filters?.find(x => x.name === 'SiteID')?.value
        if(siteId) {
            let body = {
                siteID: siteId,
                startDate: start,
                endDate: end,
                Filters: ganttFilter.filters
            }
            let data = await GanttApi.getGanttData(body)
            let chambers=[]
            let _originOrders=[]
            let ordersFilter = []
            let ordersTypeFilter = []
            if(data && data.data){
                data.data?.forEach((d) => {
                    if(d.TYPECODE){
                        ordersTypeFilter.push({label:utils.getOrderTypeLabel(d.TYPECODE),value:d.TYPECODE})
                        d.TYPECODE=utils.getOrderTypeLabel(d.TYPECODE)
                    }
                    ordersFilter.push({label:d.ORDNAME,value:d.OrderID})
                    // d['color'] =d
                    _originOrders.push(d)
                    d['Plans'].forEach(plan => {
                        plan?.SubPlans?.forEach(sub => {
                            let gloveBox
                            if(sub && sub.ChamberID)
                                gloveBox = sub.ChamberID?.substring(0).charAt(0)
                            if (!chambers.find(x => x.name === 'ch'+sub.ChamberID)) {
                                chambers.push(
                                    {
                                        name: 'ch'+sub.ChamberID,
                                        chamberInternalID: sub.chamberInternalID,
                                        gloveBox: gloveBox?gloveBox[0]:''
                                    })
                            }
                        })
                    })
                })

                setOriginOrders(_originOrders)
                let _chambers = chambers.sort((a, b) => a.name.localeCompare(b.name));
                let chambersFilter = []
                _chambers.forEach(ch => {
                    chambersFilter.push({label: ch.name, value: ch.chamberInternalID})
                })
                let orderNumbers = []
                _chambers.forEach(chamber => {
                    let orders = []
                    data.data.forEach(order => {
                        orderNumbers.push({label: order.ORDNAME, value: order.ORDNAME})
                        let _plans = order['Plans'][0].SubPlans.filter(x => 'ch'+x.ChamberID === chamber.name)
                        if (_plans.length > 0) {
                            _plans.forEach(_plan => {
                                _plan['startDate'] = new Date(_plan.startDate)
                                _plan['endDate'] = new Date(_plan.endDate)
                            })
                            let _order = {
                                OrderID: order.OrderID,
                                ORDNAME: order.ORDNAME,
                                TYPECODE: order.TYPECODE,
                                approved: order['Plans'][0].planStatus === 'approvedOrders',
                                planId:order['Plans'][0]['PlanID'],
                                // approved:true,
                                SIBD_ALPH_FIXATION: order.SIBD_ALPH_FIXATION,
                                SIBD_ALPH_SEEDL: order.SIBD_ALPH_SEEDL,
                                orderDatesRange: _plans,
                                color: order.color
                            }
                            orders.push(_order)
                        }
                    })
                    chamber['chamberColor'] = chambersColors[chamber.gloveBox - 1]
                    chamber['orders'] = orders
                })
                // if(type==='SiteID'){
                //     setFilterData(chambersFilter,ordersFilter,ordersTypeFilter)
                // }
                setFilterData(chambersFilter,ordersFilter,ordersTypeFilter)

                setGanttOrders(_chambers)
                // setOrdersNum(orderNumbers)
            }
        }
    }
    const setFilterData = (chambs,orders,ordersType) => {
        let _searchInputs = [...searchInputs]
        _searchInputs[props.currentUserRole==='SystemAdmin'?1:0]['data'] = chambs
        _searchInputs[props.currentUserRole==='SystemAdmin'?2:1]['data'] = orders
        _searchInputs[props.currentUserRole==='SystemAdmin'?3:2]['data'] = ordersType
        setSearchInputs(_searchInputs)
    }

    const getRange = (end) => {
        let dates = []
        let currentDate = end;
        let sevenDaysAgo = new Date(currentDate);
        sevenDaysAgo.setDate(currentDate.getDate() - 8);
        while (sevenDaysAgo < end) {
            sevenDaysAgo.setDate(sevenDaysAgo.getDate() + 1);
            dates.push({day: getCurrentDateDay(sevenDaysAgo), date: new Date(sevenDaysAgo)});
        }
        setCurrentRange(dates)
        let date = moment(end).format('MMMM YYYY')
        setCurrentMonth(date)
    }
    const getCurrentDateDay = (_date) => {
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const currentDayIndex = _date.getDay();
        return daysOfWeek[currentDayIndex];
    }

    const getNextWeek = async () => {
        let start = new Date(startDate)
        let end = new Date(endDate)
        let newStartDate = new Date(start);
        newStartDate.setDate(start.getDate() + 1);
        let newEndDate = new Date(end);
        newEndDate.setDate(end.getDate() + 1);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        getRange(newEndDate)
        let siteId = ganttFilter.filters?.find(x => x.name === 'SiteID')?.value
        await getGanttData(siteId,'SiteID',newStartDate,newEndDate).then()
    }
    const getLastWeek = async () => {
        let start = new Date(startDate)
        let end = new Date(endDate)
        let newStartDate = new Date(start);
        newStartDate.setDate(start.getDate() - 1);
        let newEndDate = new Date(end);
        newEndDate.setDate(end.getDate() - 1);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        getRange(newEndDate)
        let siteId = ganttFilter.filters?.find(x => x.name === 'SiteID')?.value
        await getGanttData(siteId,'SiteID',newStartDate,newEndDate).then()
    }

    const editOrder =async (order,chamberName) => {
        order['chamber'] = chamberName
        let _order = await OrdersApi.getOrder(order.OrderID)
        _order=_order.data
        _order['ChamberID'] = chamberName
        _order['SubPlans'] = _order['Plans']?.find(x=>x['PlanID'] === order.planId)?.SubPlans
        let dataArr = utils.arrangePlan(_order)
        let plan = dataArr?.find(x=>x['PlanID'] === order.planId)
        if(plan){
            let _plan = {..._order,...plan}
            setCurrentPlan(_plan)
        }
        setCurrentOrder(_order)
        setIsEditOrder(true)
    }
    const datePicker = () => {
        const ExampleCustomInput = forwardRef(({value, onClick}, ref) => (
            <div className={'text-[#0D3B53] text-[1.125rem] mx-[2rem] underline cursor-pointer'}
                 onClick={onClick} ref={ref}>{currentMonth}</div>
        ))
        return (
            <DatePicker
                calendarClassName={'search-input-date'}
                selected={startDate}
                onChange={(date) => setViewDate(date)}
                customInput={<ExampleCustomInput/>}
            />
        );
    }
    const setViewDate =async (date) => {
        let start = JSON.parse(JSON.stringify(date))
        let end = new Date(date.setDate(date.getDate() + 4))
        await setStartDate(new Date(start));
        await setEndDate(end);
        getRange(end)
        let siteId = ganttFilter.filters?.find(x => x.name === 'SiteID')?.value
        await getGanttData(siteId,'SiteID',new Date(start),end).then()
    }
    const filterOnChange = async (e, type) => {
        // let inputs = [...searchInputs]
        let filters = {...ganttFilter}
        let statusFilter = filters.filters?.findIndex(x => x.name === type)
        if (statusFilter > -1) {
            if (!e.target.value) {
                filters['filters'].splice(statusFilter, 1)

            } else {

                filters['filters'][statusFilter]['value'] = e.target.value.value
            }
        } else {
            filters.filters.push({name: type, value: e.target.value.value})
        }
        if (type === 'SiteID'){
            if(e.target.value) {
                setSiteID(e.target.value.value)
                setSiteName(e.target.value.label)
            }else{
                setGanttOrders([])
                setSiteID("")
                setSiteName("")
                setOriginOrders([])
            }
        }
        await setGanttFilter(filters)
        let siteId = type === 'SiteID'?e.target.value?.value:ganttFilter.filters?.find(x => x.name === 'SiteID')?.value
        await getGanttData(siteId,type,startDate,endDate).then()
    }
    const approvePlane = async (order) => {
        let result = await OrdersApi.approvePlan(order.planId,{orderid: String(order.OrderID),affectedOrders:[]})
        if(result){
            //'SiteID'
            let siteId = ganttFilter.filters?.find(x => x.name === 'SiteID')?.value
            await getGanttData(siteId,'SiteID').then()
        }

    }

    return (
        <div className=" w-width80 h-full pt-32 ml-24 font-Roboto">
            <div className={"site-search-inputs"} style={{display: 'block'}}>
                <div className={'flex justify-between'}>
                    <div className={"site-search-inputs"}>
                        {searchInputs.map(item => {
                            return <SelectAlphaIcon
                                key={item.name}
                                svgName={item.svgName}
                                data={item.data}
                                type={item.type}
                                value={ganttFilter['filters']?.find(filter=>filter.name===item.name)?.value}
                                // selected={chambersFilter['filters'].find(filter=>filter.name===item.name)?.value}
                                name={item.name}
                                label={item.label}
                                isRange={true}
                                inputWidth={'20rem'}
                                onChange={(e) => filterOnChange(e, item.name)}
                                // onChangeDate={onChangeDateFilter}
                                startDate={startDate}
                                endDate={endDate}
                                hasLine={item.hasLine}
                                inputClassName={'Select-Icon'}
                            />
                        })}

                    </div>
                    <SVGraphics className={'w-[3.5rem] mr-4 cursor-pointer'} svgname={'notes'}
                                onClick={() => setIsGanttNote(!!siteID)}/>
                </div>
                <div className={'w-[100%] flex justify-center my-[1rem] items-center'}>
                    <div className={'flex items-center'}>
                        <div onClick={() => getLastWeek()}><SVGraphics className={'w-[2rem] mr-[0.75rem]'}
                                                                       svgname={'leftArrow'}/></div>
                        {datePicker()}
                        <div onClick={() => getNextWeek()}><SVGraphics className={'w-[2rem] mr-[0.75rem]'}
                                                                       svgname={'rightArrow'}/></div>
                    </div>
                </div>
                <div>
                    <div className={'grid grid-cols-9'}>
                        <div className={'w-[143px]'}/>
                        {currentRange.map(range => {
                            return <div
                                className={`font-Roboto font-medium text-base leading-6 text-center ${range.day === 'Saturday' ? 'bg-[#D7DDDF]' : 'bg-blue-300  bg-opacity-20'} text-blue-800 border border-solid border-gray-300 relative right-[17px]`}
                                key={range.date}>
                                <div>{range.day}</div>
                                <div>{moment(range.date).format('DD MMM YYYY')}</div>
                            </div>
                        })
                        }
                    </div>
                    <OrdersGanttView orders={ganttOrders} currentRange={currentRange}
                                     editOrder={editOrder}
                                     currentUser={currentUser}
                                     approvePlane={approvePlane}
                    />
                    <div className={'w-[98.8%] h-[10px] bg-alphaTauButton'}/>
                    <DeliveryGanttView currentRange={currentRange} orders={originOrders} siteName={siteName}/>
                </div>

                {isEditOrder &&
                    <EditOrderPlan
                        order={currentPlan}
                        orderId={currentOrder.OrderID}
                        // sheddingLoss={location.state.sheddingLoss}
                        siteId={siteID}
                        cancelEdit={() => {
                            setIsEditOrder(false)
                            setCurrentOrder(null)
                            setCurrentPlan(null)
                        }}
                        planSaved={()=>planSaved()}
                    />
                }
                {isGanttNote && siteID &&
                    <GanttNotes
                        onCancel={() => setIsGanttNote(false)}
                        order={currentOrder}
                        user={currentUser}
                        siteID={siteID}
                    />
                }
            </div>
        </div>
    );
}

