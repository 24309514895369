import { authenticationService } from '../AuthinticationService'
import Config from '../../config/Config'
import restApi from './RestApi'

const globalUrl = Config.globalUrl

const ChambersApi = {
  getAllChambers: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/chambers/${user.profile.UserStorageId}/getAllChambers`, body, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },

  getChamber: async (id) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.get(`${globalUrl}/api/v1/webapp/chambers/${user.profile.UserStorageId}/${id}/getChamberById`, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  CheckChamberExisting: async (chamberID, siteID) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.get(
        `${globalUrl}/api/v1/webapp/chambers/${user.profile.UserStorageId}/CheckChamberExisting/${chamberID}/${siteID}`,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  editChamber: async (body, id) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/chambers/${user.profile.UserStorageId}/editChamber/${id}`, body, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  createChamber: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/chambers/${user.profile.UserStorageId}/createChamber`, body, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getChamberFiltersData: async () => {
    try {
      if (!authenticationService.currentUserValue) return
      let SystemAdmin = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: SystemAdmin?.token
        }
      }
      return await restApi.get(
        `${globalUrl}/api/v1/webapp/chambers/${SystemAdmin.profile.UserStorageId}/getChamberFiltersData`,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  blockChamber: async (body, id) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/chambers/${user.profile.UserStorageId}/blockChamber/${id}`, body, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  editBlockChamber: async (body, id) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(
        `${globalUrl}/api/v1/webapp/chambers/${user.profile.UserStorageId}/editblockedChamber/${id}`,
        body,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  saveTemporaryScriptRun: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(
        `${globalUrl}/api/v1/webapp/chambers/${user.profile.UserStorageId}/saveTemporaryScriptRun`,
        body,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getActiveChamberMeasurementInfo: async (body, id) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(
        `${globalUrl}/api/v1/webapp/chambers/${user.profile.UserStorageId}/getActiveChamberMeasurementInfo/${id}`,
        body,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  runAsymptoticScript: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(
        `${globalUrl}/api/v1/webapp/chambers/${user.profile.UserStorageId}/runAsymptoticScript`,
        body,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },

  getChamberCalendarActivity: async (id) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.get(
        `${globalUrl}/api/v1/webapp/chambers/${user.profile.UserStorageId}/${id}/getChamberCalendarActivity`,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getChamberMeasurmentInfo: async (id) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.get(
        `${globalUrl}/api/v1/webapp/chambers/${user.profile.UserStorageId}/getChamberMeasurmentInfo/${id}`,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getActiveChamberMeasurmentInfo: async (id) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.get(
        `${globalUrl}/api/v1/webapp/chambers/${user.profile.UserStorageId}/getActiveChamberMeasurmentInfo/${id}`,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  checkActivityChange: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(
        `${globalUrl}/api/v1/webapp/chambers/${user.profile.UserStorageId}/checkActivityChange`,
        body,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  saveBatchesToRun: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/chambers/${user.profile.UserStorageId}/saveBatchesToRun`, body, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  deleteBlockedChamber: async (id) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.delete(
        `${globalUrl}/api/v1/webapp/chambers/${user.profile.UserStorageId}/deleteblockedChamber/${id}`,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  addCharges: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/chambers/${user.profile.UserStorageId}/addCharge`, body, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getCharges: async (id) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.get(`${globalUrl}/api/v1/webapp/chambers/${user.profile.UserStorageId}/getCharges/${id}`, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  deleteCharge: async (id) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.delete(`${globalUrl}/api/v1/webapp/chambers/${user.profile.UserStorageId}/deleteCharge/${id}`, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  }
}
export default ChambersApi
