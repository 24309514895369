import React, { useEffect, useState} from "react";
import Constants from "../../utils/constants";
import "react-datepicker/dist/react-datepicker.css";
import {SelectAlphaIcon} from "../input/SelectAlphaIcon";
import {TimeInputAlpha} from "../input/TimeInputAlpha";
import ChambersApi from "../../services/ApiServices/ChambersApi";
import moment from "moment";


const AsymptoticActivityCalculation = ({onCancel,saveActivityLevel, startDate,chamber, endDate,addNewCharge,asymptoticData}) => {

    const [asymptoticActivity, setAsymptoticActivity] = useState('');
    const [runAgain, setRunAgain] = useState({});
    const [selected, setSelected] = useState(null)
    const [calculatedDate, setCalculatedDate] = useState(null)
    const activity = ['Calculated','Expected']

    useEffect( () => {
        if(asymptoticData){
            setAsymptoticActivity(
                asymptoticData.Calculated
            )
            setCalculatedDate(asymptoticData.CalcDate)

        }


    },[])

    const onChangeRunAgain = (e) => {
        setSelected('run')
        let _run = {...runAgain}
        _run[e.name] = e.value
        setRunAgain(_run)
    }
    const selectActivity = (act) => {
        setRunAgain({})
        setSelected(act)
    }
    const saveCalculation = async() => {
            let _chamber = {...chamber}
            _chamber['ActivityLevel']=String(asymptoticActivity)
            // _chamber['ActivityDate']=moment(_chamber['ActivityDate']).toDate()
            _chamber['ActivityDate']=calculatedDate;
            _chamber['needToUpdateActivity']=false
            delete _chamber['lastCalcTime']
            let submit = await ChambersApi.editChamber(_chamber,_chamber.chamberInternalID)
            if(submit){
                saveActivityLevel()
            }
    }

    return (
        <div id="modalEl" tabIndex="-1" aria-hidden="true"
             className="fixed top-0 left-0 right-0 font-Roboto  w-auto z-50  py-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full bg-stone-900/60 flex items-center justify-center ">
            <div className="h-auto md:h-auto self-center w-[46.5rem]">
                <div className="relative bg-white pl-[1.875rem] w-auto rounded-lg shadow border border-indigo-600 dark:bg-gray-700">
                    <div
                        className={"rounded-full bg-[#F6F7F8] w-8 h-8 p-1 text-center float-right cursor-pointer top-[1rem] right-[2%] relative text-black font-semibold"}
                        onClick={()=>onCancel()}>
                        &#10005;
                    </div>
                    <div className="flex-col items-center  w-full px-auto justify-center justify-items-center flex">
                        <div
                            className="flex-col items-center rounded-t w-full px-auto justify-center justify-items-center mt-5">
                            <div
                                className="text-[27px] w-full font-[700] font-Roboto text-[#0D3B53] mt-[0.938rem] text-center mb-[0.5rem]">
                                Asymptotic activity calculation
                            </div>
                        </div>
                        <div className="flex-col items-center rounded-t w-full px-auto justify-center justify-items-center text-[1.125rem] mb-[2rem]">
                            On the
                            <span className={'font-semibold mx-2'}>{calculatedDate?moment(new Date(calculatedDate)).format('DD MMM YYYY h:mm A'):''}</span>
                            the asymptotic activity calculated.
                        </div>

                        <div className={'flex w-full justify-center'}>
                           <div className={`w-[15.125rem] h-[6.929rem] bg-[#F2F5FA] rounded-[0.625rem] mr-[3.125rem] flex flex-col items-center cursor-pointer`}
                                >
                                        <div className={'text-[1.125rem] text-[#444444] mt-[1.25rem] ml-[1.25rem] mb-[0.25rem] w-full'}>Result:</div>
                                        <div className={'text-alphaTauButton font-Roboto font-bold text-[1.688rem]'}>{asymptoticActivity} kBq</div>
                                </div>
                        </div>
                    </div>
                    <hr className={'mt-4 h-0.5  mr-[1.5rem] mt-[42px]'} color={"#17597C"}/>


                    <div className=" py-2 rounded-b mx-auto mt-8 flex justify-between mr-[1.5rem] mb-[0.5rem]">
                        <button type="button"
                            onClick={() => saveCalculation()}
                            // disabled={disabled}
                                className={`${Constants.buttonClass} font-Roboto w-[21.75rem] h-14 hover:bg-alphaTauButtonHover mr-5 text-white bg-alphaTauButton focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-[1.25rem] px-5 py-3 mb-4 text-center disabled:opacity-25`}>
                            Save calculated value
                        </button>
                        <button type="button"
                            onClick={() => addNewCharge()}
                            // disabled={disabled}
                                className={`${Constants.buttonClass} font-Roboto w-[21.75rem] h-14 hover:bg-alphaTauButtonHover hover:text-white text-alphaTauButton bg-[#F2F5FA] border border-alphaTauButton focus:ring-4 focus:outline-none focus:ring-blue-300 font-[700] text-[1.25rem] px-5 py-3 mb-4 text-center disabled:opacity-25`}>
                            Add new charge
                        </button>
                    </div>
                    <div className=" py-2 rounded-b mx-auto mt-1 flex justify-center mr-[1.5rem] mb-[0.938rem]">
                    <button type="button"
                        onClick={() => saveCalculation()}
                        // disabled={disabled}
                            onClick={() => onCancel()}
                            className={`${Constants.buttonClass} font-Roboto w-[21.75rem] hover:text-alphaTauButtonHover text-alphaTauButton bg-transparent focus:ring-4 focus:outline-none focus:ring-blue-300 font-[700] text-[1.25rem] px-5 py-3 mb-4 text-center disabled:opacity-25`}>
                        Select other charges
                    </button>
                    </div>
                </div>

            </div>

        </div>
    )
        ;

}

export default AsymptoticActivityCalculation;