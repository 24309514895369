import { authenticationService } from '../AuthinticationService'
import Config from '../../config/Config'
import restApi from './RestApi'

const globalUrl = Config.globalUrl

const OrdersApi = {
  getSubPlans: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/orders/${user.profile.UserStorageId}/getSubPlans`, body, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  approvePlan: async (planId, order) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(
        `${globalUrl}/api/v1/webapp/orders/${user.profile.UserStorageId}/approvePlan/${planId}`,
        order,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  savePlan: async (plan) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/orders/${user.profile.UserStorageId}/savePlan`, plan, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getOrdersFromPriority: async () => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.get(`${globalUrl}/api/v1/webapp/orders/${user.profile.UserStorageId}/getOrders`, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getOrders: async (filters) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/orders/${user.profile.UserStorageId}/getAllOrders`, {filters:filters}, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getOrderPlans: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/orders/${user.profile.UserStorageId}/getOrderPlans`, body, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getAlternativePlans: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/orders/${user.profile.UserStorageId}/getAlternativePlans`, body, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getOrder: async (id) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.get(`${globalUrl}/api/v1/webapp/orders/${user.profile.UserStorageId}/getOrder/${id}`, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getOrdersTypes: async () => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.get(`${globalUrl}/api/v1/webapp/orders/${user.profile.UserStorageId}/getOrdersTypes`, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getOrdersStatusesAndTypes: async () => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.get(`${globalUrl}/api/v1/webapp/orders/${user.profile.UserStorageId}/getOrdersStatusesAndTypes`, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },

  filteredOrders: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/orders/${user.profile.UserStorageId}/filteredOrders`, body, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  filterOrdersByPlanStatus: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(
        `${globalUrl}/api/v1/webapp/orders/${user.profile.UserStorageId}/filterOrdersByPlanStatus`,
        body,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  CalcPlans: async (order) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/orders/${user.profile.UserStorageId}/CalcPlans/`, order, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  editOrder: async (order, orderId, siteId, orderName) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.put(
        `${globalUrl}/api/v1/webapp/orders/${user.profile.UserStorageId}/editOrder/${orderId}?siteID=${siteId}&ORDNAME=${orderName}`,
        order,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  editSubPlans: async (plan, id) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(
        `${globalUrl}/api/v1/webapp/orders/${user.profile.UserStorageId}/editSubPlans/${id}`,
        plan,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getRelevantOrders: async (siteId) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.get(`${globalUrl}/api/v1/webapp/orders/${user.profile.UserStorageId}/getRelevantOrders/${siteId}`, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  calcExpectedActivity: async (orderId,body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/orders/${user.profile.UserStorageId}/calcExpectedActivity/${orderId}`, body, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getFixDate: async (orderId,body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/orders/${user.profile.UserStorageId}/fixDate/${orderId}`, body, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
}
export default OrdersApi
