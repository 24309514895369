import React, {useEffect, useState} from "react";
import SVGraphics from "../../../../assets/SVGraphics";
import moment from "moment";
import {AlertConfirm} from "../../../modals/Confirm";
import GanttApi from "../../../../services/ApiServices/GanttApi";

export function GanttNotes(props) {

    const [ganttNotes, setGanttNotes] = useState([]);
    const [entry, setEntry] = useState({});
    const currentUser = props.user;

    useEffect(()=>{
        getNotes().then().catch()
    },[])

    const getNotes = async () => {
        let notes = await GanttApi.getGanttNotes(props.siteID)
        if(notes && notes.data){
            setGanttNotes(notes.data)
        }
    }

    // const dates = ["startDate", "endDate"]
    const titleClass = 'text-alphaTauButton text-[1.25rem] font-medium text-left w-[100%]'
    const inputClass = 'text-[#444] text-[1.125rem] text-right w-[100%] '

    const onEntryChange = (event) => {
        let note = {...entry}
        let name = event.target.name
        note[name] = event.target.value
        setEntry(note)
    }
    const saveNote = async () => {
        let note = {...entry}
        // let _notes = [...ganttNotes]
        note['createdAt'] = new Date().toISOString()
        note['createdBy'] = `${currentUser.profile.FirstName} ${currentUser.profile.LastName}`
        note['UserStorageId'] = currentUser.profile.UserStorageId
        note['siteID'] =props.siteID
        let saveNote = await GanttApi.addGanttNote(note)
        if(saveNote && saveNote.data){
            getNotes().then().catch()
            setEntry({note:''})
        }
    }
    const removeNote =async (id) => {
            let noteIndex = ganttNotes.findIndex(x=> x.noteID === id)
                if(ganttNotes[noteIndex].UserStorageId === currentUser['profile'].UserStorageId){
                    // let title = <div className={'w-full flex justify-center'}><SVGraphics svgname={'alert-triangle'} className={'w-[2.5rem] h-[2.5rem]'}/></div>
                    let message = <div>Are you sure you want to delete this note?</div>
                    let alert = await AlertConfirm({  proceedLabel: 'Yes',
                        cancelLabel: 'No',options: {hasSVG: true, svgName: "alert-triangle"}},message,'')
                    if(alert) {
                       let deleteNote = GanttApi.deleteGanttNote(String(id))
                        if(deleteNote){
                            getNotes().then().catch()
                        }
                    }
                }else {
                    // let title = <div className={'w-full flex justify-center'}><SVGraphics svgname={'red-warning-circle'} className={'w-[2.5rem] h-[2.5rem]'}/></div>
                    let message =
                        <div>{`Unauthorized to delete this note!`}</div>
                    await AlertConfirm({
                        cancelLabel: 'none',
                        proceedLabel: 'OK',
                        options: {hasSVG: true, svgName: "x-circle"}
                    }, message, '')
                }
    }
    return (
        <div tabIndex="-1" aria-hidden="true"
             className="fixed top-0 right-0 font-Roboto w-auto z-50 py-4 overflow-hidden  md:inset-0 h-modal md:h-full flex items-end justify-end ">

            <div className="relative top-[1.5%] bg-white w-auto rounded-lg shadow border dark:bg-gray-700">
                <div className="h-[55rem] w-[27.563rem] ">
                    <div
                        className={" w-8 h-8 p-1 text-center float-left cursor-pointer top-[1rem] left-[3%] relative text-black font-semibold"}
                        onClick={() => props.onCancel()}>
                        &#10005;
                    </div>
                    <div className={'w-[100%] h-[100%] pl-[40px] pr-[2rem] pt-[2rem] overflow-hidden '}>
                        <div className={' gantt-notes-container w-[100%] h-auto max-h-[31.5rem] overflow-y-auto overflow-x-hidden'}>
                        {ganttNotes.map(note=>{
                            return <div key={note.noteID} className={'bg-[#F2F5FA] shadow-[0_4px_6px_0px_rgba(0,0,0,0.06)] w-[21.938rem] h-[7.125rem] rounded-[4px] mt-[0.75rem] p-4'}>
                                  <div
                                    className={" w-6 h-6 p-1 text-center float-right cursor-pointer left-[3%] relative text-black font-semibold"}
                                    onClick={() => removeNote(note.noteID)}>
                                    &#10005;
                            </div>
                                <div className={'text-[#747879] text-[0.75rem] mb-[0.813rem]'}>{moment(note.createdAt).format('DD MMM YYYY')}</div>

                                        <div className={titleClass}>&#x2022;{` ${note.createdBy}`}:</div>
                                <div className={inputClass}>{note.note}</div>
                            </div>
                        })}
                        </div>
                        {ganttNotes.length > 0 &&
                        <div className={'h-[1px] w-[21.813rem] border-b border-[#D7DDDF] mt-[1.875rem]'}/>
                        }
                        <textarea
                            name={'note'}
                            className={'h-[9.313rem] w-[21.813rem] border border-[#D7DDDF] bg-[#FFF] mt-[1.875rem] p-4'}
                            placeholder={'Add text'}
                            value={entry['note']}
                            onChange={e=>onEntryChange(e)}

                        />
                        <button onClick={()=>saveNote()} className={'float-right mr-4 mt-[1.5rem] bg-alphaTauButtonSecondary rounded-[10px] w-[10.875rem] h-[3.5rem] text-[#F2F5FA] text-[1.25rem] flex justify-center items-center' }>
                            <SVGraphics className={'w-6 h-6 mr-[0.75rem]'}  svgname={'plus-circle-white'}/>
                            Save note

                        </button>

                    </div>
                </div>
            </div>
        </div>

    )
}