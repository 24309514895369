import React from "react";
import { useTable, useSortBy,useRowSelect,usePagination } from 'react-table'
import down from "../../assets/arrows/down.svg"
import TablePagination from "./Pagination";
import {NoData} from "./NoData";
import {LoadingComponent} from "../input/LoadingComponent";
import {NoPlansData} from "./NoPlansData";


// const IndeterminateCheckbox = React.forwardRef(
//     ({ indeterminate, ...rest }, ref) => {
//         const defaultRef = React.useRef()
//         const resolvedRef = ref || defaultRef
//
//         React.useEffect(() => {
//             resolvedRef.current.indeterminate = indeterminate
//         }, [resolvedRef, indeterminate])
//
//         return (
//             <>
//                 {/*<input type="checkbox" style={{border:'1.5px solid #3072E3'}} ref={resolvedRef} class={"rounded-4px w-[16px] h-[16px]"}  {...rest} />*/}
//                 <div className={`checkbox-wrapper`}>
//                     <input type={"checkbox"}  ref={resolvedRef} {...rest}/>
//                 </div>
//                 {/*<InputCheckboxAlpha*/}
//                 {/*    ref={resolvedRef}*/}
//                 {/*/>*/}
//             </>
//         )
//     }
// )
export function Table(props) {

    let {data,columns,onClick } = props

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        state: { pageIndex },
        // state: { selectedRowIds },
    } = useTable({ columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
        useSortBy,
        usePagination,
        useRowSelect,
    )

    return (
        <>

            {props.data.length ===0 && !props.isLoading && !props.noPlansData ?
                <NoData/>:
                props.data.length ===0 && !props.isLoading && props.noPlansData ? <NoPlansData/>
                    :
                <>
                    <table {...getTableProps()} id="myTable" className={"w-full h-auto text-left text-gray-500 rounded-b-md mt-5 font-Roboto"}
                           style={{borderCollapse: 'collapse  !important'}}>
                        <thead className="text-base text-headersColor bg-tHead  sticky"
                               style={{borderRadius: '50px!important', borderCollapse: 'collapse  !important'}}>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} >
                                {headerGroup.headers.map(column => (
                                    <th
                                        key={column.id}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={`py-[1rem] px-[1rem]  first:rounded-l-md last:rounded-r-md  ${column.headerClassName}`}
                                        // onClick={(prev, curr, columnId) => {
                                        //     return sortItems(prev, curr, columnId);
                                        // }}
                                    >
                                        {column.render('Header')}
                                        <span>
                    {column.isSorted
                        ? column.isSortedDesc
                            ? <img className={'inline-block ml-[7px]'} alt={'down'} src={down}/>
                            : <img className={'rotate-180 inline-block ml-[7px]'} alt={'up'} src={down}/>
                        : ''}
                  </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()} className={"max-h-[674px]"}>
                        {page.map(row => {
                            prepareRow(row)
                            const shouldMarkRow = row.original[props.shouldMarkRow];
                            return (
                                <tr key={row.id} {...row.getRowProps()}
                                    className="border border-headersBorderColor h-[56px] max-h-[56px]"
                                    style={{
                                        backgroundColor: shouldMarkRow ? 'rgba(39, 0, 92, 0.2)' : 'white',
                                    }}
                                    {...row.getRowProps()}

                                >
                                    {row.cells.map(cell => {
                                        return (
                                            <td key={cell.id} className={`  text-ellipsis h-[56px] max-h-[56px] ${onClick ? "cursor-pointer" : ""} ${cell.column.headerClassName}`}
                                                onClick={cell.column.id !== '[editButton]' ? onClick?() => onClick(row) : null:null}
                                            >
                                                <div className={'py-[1.5rem] px-[1rem]'}>
                                                    {cell.render('Cell')}
                                                </div>

                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    {data.length >10 &&
                        <div className={"w-full"}>
                            <TablePagination
                                pageIndex={pageIndex}
                                pageOptions={pageOptions}
                                previousPage={previousPage}
                                canPreviousPage={canPreviousPage}
                                nextPage={nextPage}
                                canNextPage={canNextPage}
                            />
                        </div>
                    }

                </>
                // <div></div>

            }
            {props.isLoading &&
            <LoadingComponent
                style={{marginLeft:'15%'}}
            />
            }
            </>
    // {/*<p>Selected Rows: {Object.keys(selectedRowIds).length}</p>*/}
    // {/*<pre>*/}
    // {/*    <code>*/}
    // {/*      {JSON.stringify(*/}
    // {/*          {*/}
    // {/*              selectedRowIds: selectedRowIds,*/}
    // {/*              'selectedFlatRows[].original': selectedFlatRows.map(*/}
    // {/*                  d => d.original*/}
    // {/*              ),*/}
    // {/*          },*/}
    // {/*          null,*/}
    // {/*          2*/}
    // {/*      )}*/}
    // {/*    </code>*/}
    // {/*  </pre>*/}

    )
}
