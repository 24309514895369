import React from "react";
import {TextAlpha} from "./TextAlpha";
import {InputLabel, OutlinedInput} from "@mui/material";

export function InputNumberAlpha(props) {

    return (
/*
        <div className={props.containerClassName}>

            <input type={"number"}
                   disabled={props.disabled}
                   name={props.name}
                   className={props.inputClassName}
                   placeholder={props.placeholder}
                   onChange={props.onChange}
                   value={props.value}
                   max={props.max}
                   min={props.min}
            />
            <TextAlpha text={props.label} className={props.labelClassName}/>

        </div>
*/

    <div className={props.containerClassName}>
        <InputLabel className={props.labelClassName}>{props.label}</InputLabel>
        <OutlinedInput
            error={props.isError}
            className={`inputClass ${props.inputClassName}`}
            type={'number'}
            max={props.max}
            min={props.min}
            id={props.name + props.value}
            maxLength={props.maxLength}
            // error={isErr}
            // defaultValue={props.value}
            value={props.value}
            onChange={props.onChange}
            // helperText={isErr ? errMessage : ''}
            placeholder={props.placeholder}
            disabled={props.disabled}
            // required={required}
            name={props.name}
            endAdornment={props.endAdornment}
        />
    </div>
)
}
