import React, {useState} from "react";
import {TextAlpha} from "../../../input/TextAlpha";
import {useNavigate} from "react-router-dom";
import formData from "../../../forms/formsData.json";
import {AlphaForm} from "../../../forms/AlphaForm";
import {array, object, string} from "yup";
import Constants from "../../../../utils/constants";
import { v4 as uuidv4 } from 'uuid';
import SystemAdmin from "../../../../services/ApiServices/SystemAdminApi";
import SVGraphics from "../../../../assets/SVGraphics";
import {FormCard} from "../../../forms/FormCard";


export function AddSite() {
    const right = <span className={"text-[#444444] text-2xl text-titleSize ml-2"}>&#8250;</span>
    const navigate = useNavigate();
    const phoneRegExp = Constants.phoneRegExp
    const [entry, setEntry] = useState({ActiveChambers: '0',productionAdmin:[{id:uuidv4() +"-"+ new Date().getTime()}]});
    const [adminErrorsArr, setAdminErrorsArr] = useState([])
    const [error, setError] = useState({})
    const [isError, setIsError] = useState(false)
    const [productionAdminForms, setProductionAdminForms] = useState([formData['add_Production_Admin']])
    const formType = formData['add_site']
    const siteSchema = object().shape(
        {
            SiteName: string().required('Please insert Name').default(""),
            prioritySiteID: string().required('Please insert priority Site ID').default(""),
            Country: object().required().typeError('Please insert Country'),
            ZIP: string().required('Please insert ZipCode').typeError('Please insert ZipCode').default(""),
            City: object().required().default({}),
            Street: string().required().typeError('Please insert Street').default(""),
            Number: string().required().typeError('Please insert Number').default(""),
            ActiveChambers: string().required('Please insert Chambers').typeError('Please insert Chambers').default(""),
            Phone: string(),
            technicalPersonFirstName: string().required('Please insert First Name').default(""),
            technicalPersonLastName: string().required('Please insert Last Name').default(""),
            technicalPersonPhoneNumber: string().required('Please insert Phone Number').typeError('Please insert Phone Number').matches(phoneRegExp, 'Phone number is not valid').default(""),
        })

    const productionAdminSchema = object().shape({
        FirstName: string().required('Please insert First Name').default(""),
        LastName: string().required('Please insert Last Name').default(""),
        Email: string().required('Please insert Email').matches(Constants.emailRegExp, "Please insert Valid Email").nullable(),
        PhoneNumber: string().required('Please insert Phone Number').typeError('Please insert Phone Number').matches(phoneRegExp, 'Phone number is not valid').default(""),
    });

    const arrayOfProductionAdminSchema =
        array().of(productionAdminSchema)


    const validateForm = async (currentEntry) => {
        let value = false
        let errorObj={}
        await siteSchema.validate(currentEntry, {abortEarly: false})
            .catch(async function (err) {
                for (const error of err.inner) {
                    let errorText = error.errors
                    let path = error.path
                    errorObj[path] = errorText[0]
                }
                setError({...errorObj})
                setIsError(true)
                value = true
            });
        setError({...errorObj})
        return value
    }

    const validateAdminsArr = async () => {
        let value = false
        let currentArr = entry['productionAdmin']
        let adminErrors = currentArr.map(()=> {return []})
        await arrayOfProductionAdminSchema.validate(currentArr, {abortEarly: false})
            .catch(async function (err) {
                for (const error of err.inner) {
                        let index = error.path.substring(1, 2)
                        let path = error.path.substring(4)
                    adminErrors[index][path] = error.errors
                }
                value = true
                setAdminErrorsArr([...adminErrors])
            });
        setAdminErrorsArr(adminErrors)

        return value
    }

    const onChange = async (name, value ) => {
        let currentEntry = {...entry}
        if (value === "") {
            value = null
        }
        currentEntry[name] = value
        setEntry({...currentEntry})
        if (error[name]) {
            await validateForm(currentEntry)
        }
    }
    const onProdAdminChange = async (name, value,index ) => {
        let currentEntry = {...entry}
        if (value === "") {
            value = null
        }
        currentEntry['productionAdmin'][index][name] = value
        if (index < adminErrorsArr.length && adminErrorsArr[index][name]) {
            await validateAdminsArr(entry)
        }
        setEntry({...currentEntry})
    }

    const addAdminForm = () => {
        let currentEntry = {...entry}
        let currentForm = [...productionAdminForms]
        currentEntry['productionAdmin'].push({id:uuidv4() +"-"+ new Date().getTime()})
        currentForm.push(formData['add_Production_Admin'])
        setEntry({...entry})
        setProductionAdminForms([...currentForm])
    }

    const removeAdminForm = (id) => {
        let currentEntry = {...entry}
        let currentForm = [...productionAdminForms]
        currentEntry['productionAdmin'].splice(id, 1)
        currentForm.splice(id, 1)
        setProductionAdminForms([...currentForm])
        setEntry({...currentEntry})
    }

    const createSite = async() => {
        let validateSite = await validateForm(entry)
        let validateAdmins = await validateAdminsArr(entry)
        if(!validateAdmins && !validateSite){
            let ObjToSend={...entry}
            ObjToSend['Country'] = ObjToSend['Country'].label
            ObjToSend['State'] = ObjToSend['State']?.label?ObjToSend['State']?.label:ObjToSend['Country']
            ObjToSend['City'] = ObjToSend['City']?.label?ObjToSend['City']?.label:ObjToSend['Country']
            let siteInfo = {
                SiteInfo: ObjToSend,
               productionAdmins:entry['productionAdmin']
            }
            let submit = await SystemAdmin.createSite(siteInfo)
            if(submit && submit.data){
                navigate('/systemAdmin/sites')
            }
        }
    }
    const cancelEdit = async () => {
        navigate('/systemAdmin/sites')
    }

    return(
        <div className="w-full h-full pt-32 ml-24 overflow-auto">
            <div className={'w-11/12'}>
                <div className={'mb-7'}>
                    <TextAlpha text={"Sites"}
                               className={"text-alphaTauText text-titleSize cursor-pointer font-bold"}
                               onClick={() => navigate('/systemAdmin/sites')}/>
                    <TextAlpha text={right}/>
                    <TextAlpha text={" New Site"} className={"text-alphaTauButton text-titleSize"}/>
                </div>

                {formType.map(item => {
                    return <FormCard
                        id={item['key']}
                        cardClass={'pb-[1.25rem] mb-[1.5rem]'}
                        cardTitle={item['text']}
                        data={<> <AlphaForm
                            data={item['inputsArr']}
                            formData={entry}
                            country={entry['Country']?.value}
                            onChange={e => onChange(e.target.name, e.target.value)}
                            containerClassName={"mb-6 mr-12"}
                            labelClassName={Constants.inputLabelClass}
                            inputClassName={Constants.inputClass}
                            isShown={error}
                            isError={isError}
                        />
                        </>
                        }

                    />
                })}
                {productionAdminForms.map((item, index) => {
                    let adminEntry = entry['productionAdmin'][index]
                    return <FormCard
                        id={"ProductionAdmin" + index}
                        cardClass={'pb-[1.25rem] mb-[1.5rem]'}
                        cardTitle={"Production admin info"}
                        length={productionAdminForms.length}
                        hasDeleteIcon={true}
                        deleteCurrent={()=>removeAdminForm(index)}
                        data={<>
                            <AlphaForm
                            data={item}
                            index={"-ProductionAdmin" + adminEntry.id}
                            formData={adminEntry}
                            country={entry['Country']?.value}
                            onChange={e => onProdAdminChange(e.target.name, e.target.value,index)}
                            containerClassName={"mb-6 mr-12"}
                            labelClassName={Constants.inputLabelClass}
                            inputClassName={Constants.inputClass}
                            isShown={adminErrorsArr[index]}
                            isError={isError}
                        />
                        </>
                        }

                    />
                })}

                <div
                    className={'text-alphaTauButton text-[1.25rem] h-[3.5rem] font-bold font-Roboto flex items-center cursor-pointer'}
                    onClick={() => addAdminForm()}>
                    <SVGraphics className={'w-6 h-6 mr-[0.75rem]'} svgname={'plus-circle'}/>
                    Add
                </div>

                <div className={'float-right my-8 flex items-center pb-[3.125rem]'}>
                    <button type="button"
                            onClick={() => createSite()}
                            className="mr-12 w-loginButton text-white bg-alphaTauButton hover:bg-alphaTauButtonHover font-Roboto font-medium rounded-rounded10 text-textSize px-5 py-2.5 text-center">
                        Create
                    </button>
                    <div
                        className={'mr-10 underline underline-offset-4 text-alphaTauButton cursor-pointer text-[1.313rem]'}
                        onClick={() => cancelEdit()}>Cancel
                    </div>
                </div>
            </div>
        </div>
    )
}